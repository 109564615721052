import ConditionalWrapper from '@aurora/shared-client/components/common/ConditionalWrapper/ConditionalWrapper';
import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import ThemedContainer from '@aurora/shared-client/components/common/ThemedContainer/ThemedContainer';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import NodeAvatar from '@aurora/shared-client/components/nodes/NodeAvatar/NodeAvatar';
import NodeIcon from '@aurora/shared-client/components/nodes/NodeIcon/NodeIcon';
import type { BoardPagesAndParams } from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import type {
  Board,
  CoreNodeEdge,
  GroupHub
} from '@aurora/shared-generated/types/graphql-schema-types';
import { ConversationStyle } from '@aurora/shared-generated/types/graphql-schema-types';
import { NodeType } from '@aurora/shared-types/nodes/enums';
import { EndUserComponent, EndUserPages } from '@aurora/shared-types/pages/enums';
import type { ColorCssVariableOrValue } from '@aurora/shared-types/styles';
import { CommonColorCssVariables } from '@aurora/shared-types/styles';
import type { TextAlignment } from '@aurora/shared-types/texts/enums';
import { getLog } from '@aurora/shared-utils/log';
import React, { useContext, useRef } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { getBoardViewAllPageAndQueryPathParams } from '../../../helpers/boards/ConversationStyleBehaviorHelper';
import type { NodeHeaderContentWidth, NodeHeaderLayout } from '../../../types/enums';
import { NodeHeaderVariant } from '../../../types/enums';
import type { GroupHubDetailsQueryVariables, NodeViewFragment } from '../../../types/graphql-types';
import CommunityUsersCount from '../../community/CommunityUsersCount/CommunityUsersCount';
import EditContext from '../../context/EditContext/EditContext';
import useGroupHubDetails from '../../grouphubs/useGroupHubDetails';
import useTranslation from '../../useTranslation';
import NodeHeaderActionButton from '../NodeHeaderActionButton/NodeHeaderActionButton';
import NodeTopicsCount from '../NodeTopicsCount/NodeTopicsCount';
import localStyles from './NodeHeader.module.pcss';
import BannerSearch from '../../search/BannerSearch/BannerSearch';

const log = getLog(module);

interface NodeHeaderVariantCommonProps {
  /**
   * The horizontal alignment of content within the header
   */
  alignment: TextAlignment;
  /**
   * The width of content within the header
   */
  contentWidth: NodeHeaderContentWidth;
  /**
   * Whether to display the action button
   */
  useActionButton?: boolean;
  /**
   * Whether to display the search widget
   */
  useSearch?: boolean;
  /**
   * Property to specify whether search is global or restricted to node.
   */
  isSearchGlobal?: boolean;
}

interface LargeVariantProps extends NodeHeaderVariantCommonProps {
  /**
   * The node header variant type
   */
  type: NodeHeaderVariant.LG;
}

interface MediumVariantProps
  extends Omit<NodeHeaderVariantCommonProps, 'alignment' | 'contentWidth'> {
  /**
   * The node header variant type
   */
  type: NodeHeaderVariant.MD;
  /**
   * The orientation of the two-column layout for the header
   */
  layout: NodeHeaderLayout;
  /**
   * Whether to display the node avatar. Does not display when added to a Community node.
   */
  useAvatar: boolean;
}

interface SmallVariantProps
  extends Pick<NodeHeaderVariantCommonProps, 'alignment' | 'contentWidth'> {
  /**
   * The node header variant type
   */
  type: NodeHeaderVariant.SM;
}

export type NodeHeaderVariantProps = LargeVariantProps | MediumVariantProps | SmallVariantProps;

interface Props {
  /**
   * The node to display the header for
   */
  node: NodeViewFragment;
  /**
   * The variant props for the node header
   */
  nodeHeaderVariantProps: NodeHeaderVariantProps;
  /**
   * The title for the component
   */
  title?: string;
  /**
   * The description for the component
   */
  description?: string;
  /**
   * The title for the action button
   */
  actionButtonTitle?: string;
  /**
   * Whether to display the title. When false, visible to screen readers only.
   */
  showTitle?: boolean;
  /**
   * Whether to display the description. When false, visible to screen readers only.
   */
  showDescription?: boolean;
  /**
   * The font color used in the title and description
   */
  fontColor?: ColorCssVariableOrValue;
  /**
   * Whether to include the count stats for the node. For a community node, the members and online users counts
   * are displayed. For "places" nodes, the post and solutions counts are displayed
   */
  useCountStats?: boolean;
  /**
   * The spacing at the top of the component, in pixels.
   */
  paddingTop?: number;
  /**
   * The spacing at the bottom of the component, in pixels.
   */
  paddingBottom?: number;
  /**
   * Class name(s) to apply to the component
   */
  className?: string;
}

/**
 * User defined type guard
 *
 * @param variantProps the node header variant props
 */
export function isMediumNodeHeaderVariant(
  variantProps: NodeHeaderVariantProps
): variantProps is MediumVariantProps {
  return variantProps && variantProps.type === NodeHeaderVariant.MD;
}

/**
 * User defined type guard
 *
 * @param variantProps the node header variant props
 */
export function isSmallNodeHeaderVariant(
  variantProps: NodeHeaderVariantProps
): variantProps is SmallVariantProps {
  return variantProps && variantProps.type === NodeHeaderVariant.SM;
}

/**
 * Function for getting the default boundary padding for the NodeHeader
 *
 * @param type the type from the nodeHeaderVariantProps interface
 */
export function getDefaultNodeHeaderPadding(type: NodeHeaderVariant): number {
  if (type === NodeHeaderVariant.LG) {
    return 50;
  } else {
    return 25;
  }
}

function isNodeBoard(node): node is Board {
  return node.nodeType === NodeType.BOARD;
}

/**
 * User defined type guard for working with GroupHub nodes
 *
 * @param node the node
 */
function isNodeGroupHub(node): node is GroupHub {
  return node.nodeType === NodeType.GROUPHUB;
}

/**
 * Node Header widget
 *
 * @author Adam Ayres, Jonathan Bridges
 */
const NodeHeader: React.FC<React.PropsWithChildren<Props>> = ({
  node,
  nodeHeaderVariantProps,
  title,
  description,
  actionButtonTitle,
  showTitle = true,
  showDescription = true,
  fontColor = CommonColorCssVariables.BODY_TEXT,
  useCountStats = false,
  paddingTop = getDefaultNodeHeaderPadding(nodeHeaderVariantProps?.type),
  paddingBottom = getDefaultNodeHeaderPadding(nodeHeaderVariantProps?.type),
  className
}: Props) => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage, loading: textLoading } = useTranslation(EndUserComponent.NODE_HEADER);
  const { showEditControls } = useContext(EditContext);
  const { router, Link } = useEndUserRoutes();
  const { contextNode } = useContext(AppContext);
  const {
    publicConfig: { quiltsV2Enabled }
  } = useContext(TenantContext);
  const { type } = nodeHeaderVariantProps || {};
  const boardId = useRef('');
  const categoryId = useRef('');
  const currentPage = router.getCurrentPageName();
  const isPostCountClickable =
    currentPage === EndUserPages.BlogBoardPage ||
    currentPage === EndUserPages.TkbBoardPage ||
    currentPage === EndUserPages.ForumBoardPage ||
    currentPage === EndUserPages.IdeaBoardPage ||
    currentPage === EndUserPages.EventBoardPage;

  const variables: GroupHubDetailsQueryVariables = {
    id: contextNode.id,
    useGroupHubDescendants: true,
    useMembershipInformation: true,
    useGroupHubPolicies: true
  };

  const {
    queryResult: { data },
    loading: groupHubInfoLoading
  } = useGroupHubDetails(variables, contextNode.nodeType !== NodeType.GROUPHUB);

  if (groupHubInfoLoading || textLoading) {
    return null;
  }

  if (!node) {
    log.warn('Node header has no valid node!');
    return null;
  }

  const { nodeType } = node;

  const groupHub = data?.groupHub;

  // For props that are not available to all variants default parameters cannot be set above using object destructuring.
  // Here we assign default parameters behind user-defined type guards
  let alignment: TextAlignment;
  let contentWidth: NodeHeaderContentWidth;
  let layout: NodeHeaderLayout;
  let useActionButton: boolean;
  let useAvatar: boolean;
  let useSearch: boolean;
  let isSearchGlobal: boolean;
  let groupHubId: string;

  if (!isMediumNodeHeaderVariant(nodeHeaderVariantProps)) {
    ({ alignment, contentWidth } = nodeHeaderVariantProps);
  }
  if (isMediumNodeHeaderVariant(nodeHeaderVariantProps)) {
    ({ layout, useAvatar } = nodeHeaderVariantProps);
  }
  if (!isSmallNodeHeaderVariant(nodeHeaderVariantProps)) {
    ({ useActionButton, useSearch, isSearchGlobal } = nodeHeaderVariantProps);
  }
  if (isNodeBoard(node) && node.parent) {
    ({
      displayId: boardId.current,
      parent: { displayId: categoryId.current }
    } = node);
  } else if (isNodeGroupHub(contextNode) && useActionButton) {
    const {
      userContext: { canUpdateNode }
    } = contextNode;
    const { isMember, descendants } = groupHub;
    const { edges } = descendants || {};
    const forum = (edges as Array<CoreNodeEdge>)?.find(
      edge => (edge.node as Board).conversationStyle === ConversationStyle.Forum
    )?.node;
    if (forum && (isMember || canUpdateNode)) {
      boardId.current = forum.displayId;
      groupHubId = node.displayId;
    }
  }

  /**
   * Renders the title and description
   */
  function renderTitleAndDescription(): React.ReactElement {
    return (
      <>
        {title && (
          <h1
            className={cx(showTitle ? 'lia-title' : 'sr-only')}
            style={{ color: fontColor }}
            data-testid="NodeHeader.title"
          >
            {formatMessage('title', { title })}
          </h1>
        )}
        {description && (
          <span
            className={cx('lia-description', { 'sr-only': !showDescription })}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </>
    );
  }

  /**
   * Renders the stat counts
   *
   * @param wrapperClassName class name(s) to apply to the wrapping div
   */
  function renderStatCounts(wrapperClassName?: string): React.ReactElement {
    const renderContent =
      nodeType === NodeType.COMMUNITY ? (
        <>
          <CommunityUsersCount className={cx('lia-count lia-g-divider lia-g-is-color-mimic')} />
          <CommunityUsersCount
            className={cx('lia-count lia-g-divider lia-g-is-color-mimic')}
            showOnlineOnly
          />
          <NodeTopicsCount
            className={cx('lia-count lia-g-divider lia-g-is-color-mimic')}
            useIcon={false}
            node={node}
            useNeutralLabel
          />
        </>
      ) : (
        <NodeTopicsCount
          className={cx('lia-count lia-g-divider')}
          useIcon={false}
          node={node}
          useNeutralLabel
        />
      );

    if (isPostCountClickable) {
      const { page, pathParams } = getBoardViewAllPageAndQueryPathParams(contextNode);
      return (
        <Link<BoardPagesAndParams> route={page} params={pathParams} passHref legacyBehavior={true}>
          <a data-testid="NodeHeader.PostCountLink" className={cx('lia-stats', wrapperClassName)}>
            {renderContent}
          </a>
        </Link>
      );
    } else {
      return <div className={cx('lia-stats', wrapperClassName)}>{renderContent}</div>;
    }
  }

  /**
   * Renders the content for either large or small variant type
   */
  function renderLargeOrSmallVariant(): React.ReactElement {
    return (
      <>
        {renderTitleAndDescription()}
        {useSearch && <BannerSearch className={cx('lia-search')} isSearchGlobal={isSearchGlobal} />}
        {(useActionButton || useCountStats) && (
          <div className={cx('lia-footer-wrap', { 'lia-no-button': !useActionButton })}>
            {useActionButton && (
              <NodeHeaderActionButton
                node={node}
                actionButtonTitle={actionButtonTitle}
                alignment={alignment}
                groupHubId={groupHubId}
                boardId={boardId}
                categoryId={categoryId}
              />
            )}
            {useCountStats && renderStatCounts()}
          </div>
        )}
      </>
    );
  }

  /**
   * Renders the content for the medium variant type
   */
  function renderMediumVariant(): React.ReactElement {
    const isCommunity = nodeType === NodeType.COMMUNITY;
    // node avatar is never available for community nodes
    const useNodeAvatar = !isCommunity && useAvatar;
    // when on a Community, count stats appear in right col
    const isCountStatsInRightCol = isCommunity && useCountStats;
    const isCountStatsInLeftCol = !isCommunity && useCountStats;
    const useLeftCol = title || description || isCountStatsInLeftCol;
    const useRightCol = useSearch || useActionButton || isCountStatsInRightCol;
    // when on a Community, decrease spacing between stats in the right col and text elements in the left col on mobile
    const isTextVisible = !!((showTitle && title) || (showDescription && description));
    const isCommunityStatsBelowText = isCountStatsInRightCol && !useSearch && isTextVisible;

    return (
      <>
        <ConditionalWrapper
          condition={useNodeAvatar}
          className={cx('d-flex align-items-center flex-column flex-md-row', {
            'lia-g-mb-25 lia-g-mb-md-0': useRightCol
          })}
        >
          {useNodeAvatar && (
            <NodeAvatar
              className={cx('lia-avatar')}
              node={node}
              size={IconSize.PX_80}
              fallback={(): React.ReactElement => (
                <NodeIcon className={cx('lia-avatar')} node={node} size={IconSize.PX_80} useFrame />
              )}
            />
          )}
          {useLeftCol && (
            <div className={cx({ 'lia-col-left': isTextVisible || isCountStatsInLeftCol })}>
              {renderTitleAndDescription()}
              {isCountStatsInLeftCol && renderStatCounts()}
            </div>
          )}
        </ConditionalWrapper>
        {useRightCol && (
          <div className={cx('lia-col-right')}>
            {useSearch && (
              <BannerSearch className={cx('lia-search')} isSearchGlobal={isSearchGlobal} />
            )}
            <ConditionalWrapper
              condition={useActionButton && isCountStatsInRightCol}
              className={cx('lia-footer-wrap lia-align-right')}
            >
              {isCountStatsInRightCol &&
                renderStatCounts(cx({ 'lia-spacer': isCommunityStatsBelowText }))}
              {useActionButton && (
                <NodeHeaderActionButton
                  node={node}
                  actionButtonTitle={actionButtonTitle}
                  groupHubId={groupHubId}
                  boardId={boardId}
                  categoryId={categoryId}
                />
              )}
            </ConditionalWrapper>
          </div>
        )}
      </>
    );
  }

  const headerClassName =
    type === NodeHeaderVariant.MD
      ? `lia-header-grid lia-layout-${layout}`
      : `lia-header-flex lia-alignment-${alignment} lia-content-width-${contentWidth}`;

  return (
    <>
      <ThemedContainer
        className={cx(
          type !== NodeHeaderVariant.MD ? `lia-container lia-container-alignment-${alignment}` : '',
          { 'lia-node-header-edit-mode': showEditControls && !quiltsV2Enabled }
        )}
      >
        <div
          className={cx(headerClassName, className)}
          style={{ color: fontColor, padding: `${paddingTop}px 0 ${paddingBottom}px 0` }}
        >
          {nodeHeaderVariantProps.type === NodeHeaderVariant.MD
            ? renderMediumVariant()
            : renderLargeOrSmallVariant()}
        </div>
      </ThemedContainer>
    </>
  );
};
export default NodeHeader;
