import { KeyBoardEvents } from '@aurora/shared-types/community/enums';
import { tabbable } from 'tabbable';
import type { RefObject } from 'react';
import { useEffect } from 'react';

/**
 * Hook to manage the tab keyboard events for an Overlay. This manages the tab behavior pressing tab
 * and shit-tab when the current focusable element is before and after the overlay.
 *
 * @param elementBeforeOverlay element ref before the overlay
 * @param overlayElement element ref of the overlay
 * @param overlayOpen whether the overlay is open
 * @param onTabOutOfOverlay callback when the next tab will move the focus
 * outside the element-before-the-overlay and the overlay.
 *
 * @author Adam Ayres
 */
export default function useOverlayKeyboardNavigation(
  elementBeforeOverlay: RefObject<HTMLElement>,
  overlayElement: RefObject<HTMLElement>,
  overlayOpen: boolean,
  onTabOutOfOverlay: (event: KeyboardEvent, direction: 'in' | 'out') => void
): void {
  function handleKeyDown(event: KeyboardEvent): void {
    if (
      event.key === KeyBoardEvents.TAB &&
      overlayOpen &&
      elementBeforeOverlay.current &&
      overlayElement.current
    ) {
      const searchFormTabbableElements = tabbable(elementBeforeOverlay.current);
      const lastTabbableElementInSearchForm = searchFormTabbableElements.at(-1);

      const bodyTabbableElements = tabbable(document.body);

      const indexOfLastElementOfSearchFormInBody = bodyTabbableElements.indexOf(
        lastTabbableElementInSearchForm
      );

      const searchResultsTabbableElements = tabbable(overlayElement.current);

      if (!event.shiftKey) {
        // if last tabbable element in search form then next tab should move to the
        // first element in the search results
        if (lastTabbableElementInSearchForm === document.activeElement) {
          event.preventDefault();
          searchResultsTabbableElements[0]?.focus();
        } else if (searchResultsTabbableElements.at(-1) === document.activeElement) {
          // if last tabbable element in search results then next tab should move to the
          // first element in the DOM after search form
          event.preventDefault();

          bodyTabbableElements.at(indexOfLastElementOfSearchFormInBody + 1)?.focus();
          onTabOutOfOverlay(event, 'out');
        }
      } else {
        // if the first tabbable element in the search results then move to the last element
        // in the search form
        if (searchResultsTabbableElements.at(0) === document.activeElement) {
          event.preventDefault();
          lastTabbableElementInSearchForm?.focus();
        }
        // if first tabbable element in the element-before-the-overlay then call the callback
        // for tabbing outside of it.
        if (searchFormTabbableElements.at(0) === document.activeElement) {
          onTabOutOfOverlay(event, 'in');
        }
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return (): void => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });
}
