import QueryHandler from '@aurora/shared-client/components/common/QueryHandler/QueryHandler';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import { UserOnlineStatus } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type {
  CommunityUserCountQuery,
  CommunityUserCountQueryVariables
} from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import communityUsersCountQuery from './CommunityUsersCount.query.graphql';

interface Props {
  /**
   * Set a custom element for the component
   */
  as?: React.ElementType;
  /**
   * Class name(s) to apply to the component
   */
  className?: string;
  /**
   * Whether or not to constrain the users count by online users
   */
  showOnlineOnly?: boolean;
}
/**
 * Renders either the total users count or the online users count for the community
 *
 * @author Jonathan Bridges
 */
const CommunityUsersCount: React.FC<React.PropsWithChildren<Props>> = ({
  as: Component = 'span',
  className,
  showOnlineOnly = false
}: Props) => {
  const cx = useClassNameMapper();
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.COMMUNITY_USERS_COUNT
  );
  const { isAnonymous } = useRegistrationStatus();

  const variables: CommunityUserCountQueryVariables = {
    constraints: {
      onlineStatus: {
        eq: UserOnlineStatus.Online
      }
    }
  };

  const queryResult = useQueryWithTracing<
    CommunityUserCountQuery,
    CommunityUserCountQueryVariables
  >(module, communityUsersCountQuery, {
    ...(showOnlineOnly && { variables }),
    skip: isAnonymous
  });

  if (textLoading) {
    return null;
  }

  return (
    <QueryHandler queryResult={queryResult} loadingSize={null}>
      {(): React.ReactNode => {
        const usersCount = queryResult?.data?.usersCount;
        return (
          <Component className={cx(className)} data-testid="CommunityUsersCount">
            {formatMessage(showOnlineOnly ? 'online' : 'totalUsers', { usersCount })}
          </Component>
        );
      }}
    </QueryHandler>
  );
};
export default CommunityUsersCount;
