import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import { NodeType } from '@aurora/shared-types/nodes/enums';
import { CommonColorCssVariables } from '@aurora/shared-types/styles';
import {
  merge,
  mergeLeft,
  UndefinedValueMergeBehavior
} from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import { useContext } from 'react';
import { WidgetEditLevel } from '../../../helpers/quilt/PageEditorHelper';
import { NodeHeaderOptionsToDisplay } from '../../../types/enums';
import type { GroupHubDetailsQueryVariables } from '../../../types/graphql-types';
import useGroupHubDetails from '../../grouphubs/useGroupHubDetails';
import type { NodeHeaderVariantProps } from '../../nodes/NodeHeader/NodeHeader';
import { getDefaultNodeHeaderPadding } from '../../nodes/NodeHeader/NodeHeader';
import {
  getDefaultLargeNodeHeaderVariantProps,
  getDefaultMediumNodeHeaderVariantProps,
  getDefaultNodeHeaderVariantProps,
  getDefaultSmallNodeHeaderVariantProps,
  isNodeAbleToDisplayActionButton
} from './NodeBannerWidgetHelper';
import type { BannerWidgetProps } from '../BannerWidget/types';

export interface NodeBannerProps extends BannerWidgetProps {
  /**
   * The variant props for the node header
   */
  nodeHeaderVariantProps: NodeHeaderVariantProps;
  /**
   * Whether or not to display the title and description. When is none, visible to screen readers only.
   */
  showNodeHeaderTitleAndDescription?: NodeHeaderOptionsToDisplay;
  /**
   * Whether or not to include the count stats for the node. For a community node, the members and online users counts
   * are displayed. For "places" nodes, the post and solutions counts are displayed
   */
  useNodeHeaderCountStats?: boolean;
  /**
   * The spacing at the top and bottom of the NodeHeader
   */
  nodeHeaderPadding?: { paddingTop: number; paddingBottom: number };
}

const defaultProps: Partial<NodeBannerProps> = {
  visualEffects: {
    showBottomBorder: true
  },
  showNodeHeaderTitleAndDescription: NodeHeaderOptionsToDisplay.BOTH,
  fontColor: CommonColorCssVariables.BODY_TEXT,
  useNodeHeaderCountStats: false,
  editLevel: WidgetEditLevel.FULL
};

/**
 * Returns final props for banner widget
 * @param props passed props
 * @author Luisina Santos
 */
export function useNodeBannerWidgetFinalProps(props: NodeBannerProps): [boolean, NodeBannerProps] {
  const adjustedProps: NodeBannerProps = merge(defaultProps, props, {
    undefinedMergeBehavior: UndefinedValueMergeBehavior.IGNORE_BEFORE_MERGE,
    mergeNested: false
  });
  const { nodeHeaderPadding, nodeHeaderVariantProps } = adjustedProps;
  const { contextNode } = useContext(AppContext);
  const { nodeType, userContext } = contextNode;
  const isCommunity = nodeType === NodeType.COMMUNITY;
  const { registrationStatus, confirmEmailStatus } = useRegistrationStatus();

  const variables: GroupHubDetailsQueryVariables = {
    id: contextNode.id,
    useGroupHubDescendants: true,
    useMembershipInformation: true
  };

  const {
    queryResult: { data: groupHubData },
    loading: groupHubInfoLoading
  } = useGroupHubDetails(variables, contextNode.nodeType !== NodeType.GROUPHUB);

  const useActionButtonProps: boolean = isNodeAbleToDisplayActionButton(
    contextNode,
    userContext,
    registrationStatus,
    confirmEmailStatus,
    groupHubData?.groupHub
  );

  const defaultNodeHeaderVariantProps: NodeHeaderVariantProps = getDefaultNodeHeaderVariantProps(
    isCommunity,
    nodeHeaderVariantProps,
    getDefaultLargeNodeHeaderVariantProps(isCommunity, useActionButtonProps),
    getDefaultMediumNodeHeaderVariantProps(isCommunity, useActionButtonProps),
    getDefaultSmallNodeHeaderVariantProps()
  );

  const finalNodeHeaderVariantProps: NodeHeaderVariantProps = mergeLeft(
    defaultNodeHeaderVariantProps,
    nodeHeaderVariantProps ?? {},
    true,
    false
  );

  const finalNodeHeaderPadding = nodeHeaderPadding ?? {
    paddingTop: getDefaultNodeHeaderPadding(finalNodeHeaderVariantProps.type),
    paddingBottom: getDefaultNodeHeaderPadding(finalNodeHeaderVariantProps.type)
  };

  return [
    groupHubInfoLoading,
    {
      ...adjustedProps,
      nodeHeaderVariantProps: finalNodeHeaderVariantProps,
      nodeHeaderPadding: finalNodeHeaderPadding
    }
  ];
}
