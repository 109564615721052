import { sameWidthPopperConfig } from '@aurora/shared-client/helpers/ui/PopperJsHelper';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React, { useRef, useState } from 'react';
import { Overlay, useClassNameMapper } from 'react-bootstrap';
import { flushSync } from 'react-dom';
import useOverlayKeyboardNavigation from '../../useOverlayKeyboardNavigation';
import useTranslation from '../../useTranslation';
import InlineSearchForm from '../InlineSearchForm/InlineSearchForm';
import useCommunitySearchChatProperties from '../useCommunitySearchChatProperties';
import localStyles from './BannerSearch.module.pcss';
import formSchema from './EmbeddedSearchForm.form.json';
import dynamic from 'next/dynamic';
import { SearchArea } from '../InlineSearchResults/enums';

const InlineSearchResults = dynamic(() => import('../InlineSearchResults/InlineSearchResults'));
const AiSearchButton = dynamic(() => import('../../aichat/AiSearchButton/AiSearchButton'));
const SpotlightSearchOverlay = dynamic(
  () => import('../SpotlightSearchOverlay/SpotlightSearchOverlay')
);

interface Props {
  /**
   * Property to specify whether search is global or restricted to node.
   */
  isSearchGlobal?: boolean;
  /**
   * Classname(s) to apply to the spotlight search.
   */
  className?: string;
  /**
   * Whether to show the AI search chat
   */
  useAiSearchChat?: boolean;
}

interface SearchData {
  search: string;
}

/**
 * A form for searching messages with the results shown in an overlay below the
 * search input used in the page Banner.
 *
 * @constructor
 * @author Adam Ayres, Willi Hyde
 */
const BannerSearch: React.FC<React.PropsWithChildren<Props>> = ({
  isSearchGlobal = true,
  className
}) => {
  const queryTermThreshold = 2;
  const popperOffset = 10;
  const cx = useClassNameMapper(localStyles);

  const i18n = useTranslation(EndUserComponent.BANNER_SEARCH);
  const { loading: textLoading } = i18n;

  const [queryTerm, setQueryTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [overlayOpened, setOverlayOpened] = useState(false);
  const [aiSearchChatOpen, setAiSearchChatOpen] = useState(false);

  const searchFormReference = useRef<HTMLFormElement>(null);
  const pastQueryTerms = useRef<string[]>([]);
  // const inlineSearchResultsReference = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  const { enabled: aiChatEnabled } = useCommunitySearchChatProperties(module);

  const showOverlay =
    overlayOpened &&
    !aiSearchChatOpen &&
    (aiChatEnabled || (!aiChatEnabled && queryTerm?.length >= queryTermThreshold));
  const showResults = queryTerm?.length >= queryTermThreshold;
  const showAiButtonInResults = aiChatEnabled && !aiSearchChatOpen;
  const showAiButtonBeforeSearch = aiChatEnabled && !showResults && !aiSearchChatOpen;
  const showAiChat = aiChatEnabled && aiSearchChatOpen;
  const aiPrompt = queryTerm.length >= queryTermThreshold ? queryTerm : null;

  function handleClearSearch(event: React.TouchEvent | React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    // When clearing the search field, it will attempt to call focus on
    // the form input right after calling the onClearSearch callback.
    // We need to force this to be async so the `onFocus` handler of
    // the InlineSearchForm gets the updated state from calling `setOverlayOpened`
    // to false when AI chat is disabled.
    flushSync(() => setQueryTerm(''));
    setLoading(false);
    if (!aiChatEnabled) {
      setOverlayOpened(false);
    }
  }

  function handleCloseSearch(event = null): void {
    if (event && !searchFormReference?.current.contains(event.target)) {
      setOverlayOpened(false);
    }
  }

  function handleQueryTermChange(term: string): void {
    if (term?.length >= queryTermThreshold) {
      if (!pastQueryTerms.current.includes(term)) {
        pastQueryTerms.current.push(term);
        setLoading(true);
      }

      setOverlayOpened(true);
    } else if (!aiChatEnabled) {
      setOverlayOpened(false);
    }
    if (term.length === 0) {
      setLoading(false);
    }
    setQueryTerm(term);
  }

  useOverlayKeyboardNavigation(searchFormReference, overlayRef, showOverlay, (event, direction) => {
    if (!aiChatEnabled || direction === 'out') {
      setOverlayOpened(false);
    }
  });

  if (textLoading) {
    return null;
  }

  function getSearchFormDimensions() {
    const bottom = searchFormReference.current?.getBoundingClientRect().bottom ?? 0;
    const height = window.innerHeight - (bottom + 30);
    return { maxHeight: height };
  }

  function aiChatButtonClickHandler(event: React.MouseEvent<Element, MouseEvent>): void {
    event.stopPropagation();
    setOverlayOpened(false);
    setAiSearchChatOpen(true);
  }

  return (
    <div className={cx('lia-search-container', { 'lia-is-open': showOverlay }, className)}>
      {!aiSearchChatOpen && (
        <InlineSearchForm
          i18n={i18n}
          ref={searchFormReference}
          formId="EmbeddedSearchForm"
          formSchema={formSchema}
          formClassName={cx('lia-embedded-search-form')}
          inputGroupClassName={cx('lia-embedded-search-wrapper')}
          loading={loading}
          useClearButton={showResults}
          useCloseButton={false}
          onClearSearch={handleClearSearch}
          queryTerm={queryTerm}
          isSearchGlobal={isSearchGlobal}
          onSubmit={(formData: SearchData): void => {
            handleQueryTermChange(formData.search.trim());
          }}
          onFocus={() => {
            if (aiChatEnabled || queryTerm?.length > queryTermThreshold) {
              setOverlayOpened(true);
            }
          }}
        />
      )}

      {showOverlay && (
        <Overlay
          show={showOverlay}
          target={searchFormReference}
          placement="bottom"
          popperConfig={sameWidthPopperConfig(0, popperOffset)}
          rootClose
          rootCloseEvent="click"
          onHide={event => handleCloseSearch(event)}
        >
          {({ arrowProps, show: _show, popper, ref, style, hasDoneInitialMeasure, ...props }) => {
            return (
              <div
                className={cx('lia-search-wrap')}
                ref={element => {
                  ref(element);
                  overlayRef.current = element;
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                style={{ ...style, ...getSearchFormDimensions() }}
              >
                {showResults && (
                  <InlineSearchResults
                    className={cx('lia-search-results')}
                    searchAreas={[SearchArea.Message]}
                    isSearchGlobal={isSearchGlobal}
                    queryTerm={queryTerm}
                    onLoading={() => setLoading(false)}
                    onClose={handleCloseSearch}
                    searchFormReference={searchFormReference}
                    useAiButtonInEmptyState={aiChatEnabled}
                    onAiButtonClick={aiChatButtonClickHandler}
                  >
                    {showAiButtonInResults && (
                      <AiSearchButton
                        className={cx('lia-ai-button-inline')}
                        onClick={aiChatButtonClickHandler}
                        prompt={aiPrompt}
                      />
                    )}
                  </InlineSearchResults>
                )}
                {showAiButtonBeforeSearch && (
                  <div className={cx('lia-g-mb-15 lia-g-mx-15')}>
                    <AiSearchButton className={cx('w-100')} onClick={aiChatButtonClickHandler} />
                  </div>
                )}
              </div>
            );
          }}
        </Overlay>
      )}
      {showAiChat && (
        <SpotlightSearchOverlay
          showOverlay={true}
          initialAiChatOpen={true}
          initialPrompt={queryTerm}
          setShowOverlay={state => setAiSearchChatOpen(state)}
        />
      )}
    </div>
  );
};

export default BannerSearch;
